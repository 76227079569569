import React, { useState, useContext } from "react"
import { graphql } from "gatsby"
import { PageLayout, PageTitle, BlogLink } from "../components"
import { SEO, Utils } from "../utils"
import { Container, Row, Col, Badge, Form, FormControl, Image } from "react-bootstrap"
import { Parallax } from 'react-scroll-parallax';
import ThemeContext from "../utils/theme"

export default ({ data }) => {
  const [state, setState] = useState({
    filteredData: [],
    query: ""
  })

  const { dark } = useContext(ThemeContext)

  const allFeaturedImages = data.allFile.edges || []
  const allPosts = data.allMarkdownRemark.edges || []
  const regex = /\/[blog].*\/|$/
  const featuredImageMap = Utils.getImageMap(allFeaturedImages, regex)

  const handleChange = e => {
    const query = e.target.value

    const filteredData = allPosts.filter(post => {
      // query will run on the following fields
      const { description, title, tags, author } = post.node.frontmatter
      // standardize query
      const stdQuery = query.toLowerCase()
      return (
        post.node.excerpt.toLowerCase().includes(stdQuery) ||
        (description && description.toLowerCase().includes(stdQuery)) ||
        title.toLowerCase().includes(stdQuery) ||
        author.toLowerCase().includes(stdQuery) ||
        (tags && tags.join("").toLowerCase().includes(stdQuery))
      )
    })

    setState({
      query,
      filteredData,
    })
  }

  const { filteredData, query } = state
  const filteredPosts = query !== "" ? filteredData : allPosts

  const filteredTags = {}
  const uniqueTags = [...new Set(filteredPosts.map(({ node }) => (
    node.frontmatter.tags.map((tag) => (
      tag
    ))
  )).flat())]

  uniqueTags.map(tag => {
    filteredTags[tag] = false
    return null
  })

  const [tagState, setTagState] = useState({
    filteredTags: filteredTags
  })

  const tagClick = e => {
    const { filteredTags } = tagState
    filteredTags[e.target.dataset.tag] = !filteredTags[e.target.dataset.tag]

    setTagState({filteredTags})
  }

  return (
    <PageLayout>
      {hexBackground("blue", dark)}
      <SEO title="Blog" />

      <Container className="px-5 mb-5 text-center no-background">
        <Row style={{background: "transparent"}}>
          <Col xs={12} md={12} style={{background: "transparent"}}>
            <PageTitle title="Project Showcase" />
              <div className="tag-wrapper container px-5 p-3 no-background" style={{display: "flex", justifyContent: "center", flexWrap: "wrap"}}>
              {
                uniqueTags.map((tag) => {
                  const { filteredTags } = tagState

                  let classList = filteredTags[tag]
                    ? "primary"
                    : "light"

                  return <h3 className="px-1"><Badge variant={classList} data-tag={tag} key={tag} onClick={tagClick}>
                    {tag}
                  </Badge></h3>
                })
              }
              </div>
              <Container className="px-5 mb-5 text-center no-background">
                <Form className="aurebesh blog-filter">
                  <FormControl
                    className="bg-none search"
                    type="text"
                    placeholder="Search"
                    onChange={handleChange}
                  />
                </Form>
              </Container>
          </Col>
        </Row>
      </Container>




      <Container
        fluid
        className="p-3 w-auto text-left d-flex flex-wrap justify-content-center container"
      >
        {filteredPosts.map(({ node }) => {
          const { filteredTags } = tagState

          const activeTags = []

          Object.keys(filteredTags).map(tag => {
            if(filteredTags[tag])
              activeTags.push(tag)
            return null
          })

          if (activeTags.every(r=> node.frontmatter.tags.indexOf(r) >= 0) || activeTags.length === 0)
            return <div key={node.id} className="p-3" style={{maxWidth: "100vw"}}>
              <BlogLink
                to={node.fields.slug}
                featuredImage={featuredImageMap[node.fields.slug]}
                title={node.frontmatter.title}
                subtitle={node.frontmatter.date}
                excerpt={node.excerpt}
                link={node.frontmatter.link}
                repo={node.frontmatter.repo}
                tags={node.frontmatter.tags}
              />
            </div>
          return null
        })}
      </Container>
    </PageLayout>
  )
}

const hexBackground = (theme, dark) => {
  return(
    <div
      style={{
        height: "100%", width: "100%", background: "transparent",
        position: "absolute", top: "0", opacity: ".7"
      }}>
      <Parallax
        className="parallax-background" y={[-20, 20]} tagOuter="figure">
        <div style={backgroundParallaxStyle(theme, dark)}>
          <Image src="/assets/shiny.svg" style={{width: "30%", opacity: "0.1", position: "absolute", left: "5%", top: "30%"}}/>
          <Image src="/assets/dplyr.svg" style={{width: "30%", opacity: "0.1", position: "absolute", right: "5%", top: "15%"}}/>
          <Image src="/assets/sass.svg" style={{width: "30%", opacity: "0.1", position: "absolute", left: "10%", bottom: "5%"}}/>
          <Image src="/assets/shiny.svg" style={{width: "20%", opacity: "0.1", position: "absolute", right: "10%", bottom: "20%"}}/>
          <Image src="/assets/pipe.svg" style={{width: "16%", opacity: "0.1", position: "absolute", right: "44%", bottom: "44%"}}/>
          <Image src="/assets/devtools.svg" style={{width: "18%", opacity: "0.1", position: "absolute", left: "5%", top: "8%"}}/>
          <Image src="/assets/sass.svg" style={{width: "20%", opacity: "0.1", position: "absolute", right: "35%", top: "15%"}}/>
          <Image src="/assets/rsconnect.svg" style={{width: "10%", opacity: "0.1", position: "absolute", left: "28%", top: "20%"}}/>
          <Image src="/assets/pkgdown.svg" style={{width: "15%", opacity: "0.1", position: "absolute", right: "6%", top: "46%"}}/>
          <Image src="/assets/R6.svg" style={{width: "12%", opacity: "0.1", position: "absolute", right: "25%", top: "35%"}}/>
        </div>
      </Parallax>
    </div>
  )
}

const themes = {
  blue: {
    base: "#E7FDFF",
    darken: "#D3E7EC",
    lighter: "#D3E7EC",
    shadow: "#496268"
  },
  green: {
    base: "#CBEF90",
    darken: "#94B75B",
    lighter: "#CBEF90",
    shadow: "#5F8229"
  },
  orange: {
    base: "#ffd3b4",
    darken: "#ffd3b4",
    lighter: "#ffd3b4",
    shadow: "#ffd3b4"
  },
  red: {
    base: "#ffaaa7",
    darken: "#ffaaa7",
    lighter: "#ffaaa7",
    shadow: "#ffaaa7"
  }
}

let backgroundParallaxStyle = (theme, dark) => {
  let base = dark ? "#222" : "#ffffff"

  let style = {
    width: "100%",
    height: "100%",
    position: "relative"
  }

  return(style)
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            description
            tags
            author
            link
            repo
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
    allFile(
      filter: {
        extension: { eq: "jpg" }
        relativePath: { regex: "/feature/" }
        relativeDirectory: { regex: "/content/blog/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }
  }
`
